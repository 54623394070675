<template>
  <div>
    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('USER')}) | capitalize}}</strong>
    </div>
    <b-card v-else bg-variant="dark" text-variant="light" :title="$t('FORMS_GENERAL_DATA_TITLE', user) | capitalize">
      <b-card-text>
        <b-list-group>
          <b-list-group-item variant="dark">
            <b>{{ $t('USER_USERNAME_LABEL') | capitalize}}:</b> <span>{{ user.username }}</span>
          </b-list-group-item>
          <b-list-group-item variant="dark">
            <b>{{ $t('USER_EMAIL_LABEL') | capitalize}}:</b> {{ user.email }}
          </b-list-group-item>
          <b-list-group-item variant="dark">
            <b>{{ $t('USER_NAME_LABEL') | capitalize}}:</b> {{ user.name }}
          </b-list-group-item>
          <b-list-group-item variant="dark">
            <b>{{ $t('USER_LAST_NAME_LABEL') | capitalize}}:</b> {{ user.lastName }}
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-card>

    <b-card class="my-2" bg-variant="dark" text-variant="light" :title="$t('USER_PERMISSIONS_LABEL', user)| capitalize">
      <b-card-text>
        <b-list-group v-if="permissions.length > 0">
          <b-list-group-item v-for="permission in permissions" :key="permission.id">
            {{ $t(permission.authority) }}
          </b-list-group-item>
        </b-list-group>
        <h2 v-else>{{user.username === 'root' ? $t('ROOT_PERMISSIONS_LABEL') : $t('NO_PERMISSIONS_LABEL', user)}}</h2>
      </b-card-text>
    </b-card>

    <b-card bg-variant="dark" text-variant="light" :title="$t('USER_PROFILES_LABEL', user)">
      <b-card-text>
        <b-list-group v-if="profiles.length > 0">
          <b-list-group-item v-for="profile in profiles" :key="profile.id">
            {{ profile.name }}
          </b-list-group-item>
        </b-list-group>
        <h2 v-else>{{(user.username === 'root' ? $t('ROOT_PROFILES_LABEL') : $t('NO_PROFILES_LABEL', user)) | capitalize}}</h2>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import Users from "@/users/index";
import {CapitalFilter, LoadData} from "@/mixins";

export default {
  name: "UserProfile",
  mixins: [LoadData, CapitalFilter],
  data() {
    return {
      user: {},
      permissions: [],
      profiles: []
    }
  },
  methods: {
    loadData() {
      this.isBusy = true;
      Users.getCurrentUser()
          .then(resp => {
            this.user = resp.data;
          })
          .catch(e => console.error(e))
          .finally(() => this.isBusy = false);
      Users.getCurrentUserProfiles()
          .then(resp => {
            this.profiles = resp.data;
          })
          .catch(e => console.error(e))
      Users.getCurrentUserPermissions()
          .then(resp => {
            this.permissions = resp.data;
          })
          .catch(e => console.error(e))
    }
  }
}
</script>

<style scoped>

</style>
